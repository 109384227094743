import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/motorrad/layout";
import SEO from "../../components/motorrad/seo";
import FlexCard from "../../components/motorrad/flexcard";
import DrivingSchoolCard from "../../components/drivingschoolcard";
import HomeVideoBackground from "../../components/homevideobackground";
import settings from "../../../settings";

const bmwmotorradcard = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/home/desktop/homepage_rad.jpg`;
const bmwonroad = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/home/desktop/bmw-on-road-motorcycle-course.jpg`;
const bmwoffroad = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/home/desktop/bmw-off-road-motorcycle-course.jpg`;
const poster = `${settings.IMAGES_BASE_URL}/v2/images/poster-image-moto.jpg`;
const motoBanner = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/home/desktop/moto-qualify-banner.jpg`;
const womensOneDay = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/womens-1-day.jpg`
const fathersDayBanner = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/moto-fathers-day.jpg`;
const teamUsaBanner = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/team-usa-banner.jpg`;

const today = new Date();
const fathersDayPromo =
  today >= new Date(2024,4,17) &&
  today < new Date(2024,5,18);
const goProCard = today < new Date(2024,7,12);
class IndexPage extends React.Component {
  getDefaultCard = () => {
    return (
        <FlexCard
        rowType="row"
        image={motoBanner}
        imageAlt="GS Trophy"
        header="THE 2024 GS TROPHY IS COMING"
        subheader="COMPETE FOR A SEAT ON THE U.S. TEAM"
      >
        <p>
          Think you’re among the best of the best? You’ll be able to find out first hand on 
          October 14th as the GS Trophy Qualifier comes to the BMW Performance Center.
        </p>
        <Link to="/motorrad/gstrophy">
          <button className="btn btn-bordered">Learn More</button>
        </Link>
      </FlexCard>
    );
  };
  getFirstCard = () => {
    if (fathersDayPromo) {
      return (
        <>
          <FlexCard
            rowType="row-reverse"
            image={fathersDayBanner}
            imageAlt="GIVE DAD WHAT HE REALLY WANTS"
            header="GIVE DAD WHAT HE REALLY WANTS"
            subheader="20% OFF A GIFT CARD"
            text="Give your dad or grad (or both) what they really want – a day of riding. Get 20% off a gift card, good for many of our classes and experiences."
          >
            <p>
              Use promo code <span className="bold">24RFDHP20</span>.Must purchase card by 6/17/24. Not valid on Private Training or Two-Day MSF Basic Rider Course. Valid at our South Carolina location.
            </p>
            <a
                href="tel: 888-345-4269"
                target="_blank"
                rel="noopener noreferrer"
                className="phone-number"
              >
              <button className="btn btn-bordered">Call 888-345-4269 to book</button>
            </a>
          </FlexCard>
          <FlexCard
            rowType="row"
            image={womensOneDay}
            imageAlt="GIRL POWER"
            header="GIRL POWER"
            subheader="ALL-NEW WOMEN’S CLASSES AVAILABLE"
          >
            <p>
              Our new off-road courses are designed to make you feel comfortable on a BMW bike as you 
              explore new territory after the roads end.
            </p>
            <Link to="/motorrad/schools?desktop=4800&mobile=7500">
              <button className="btn btn-bordered">LEARN MORE</button>
            </Link>
          </FlexCard>
        </>
      );
    } 
    else {
      return (
        <>
          <FlexCard
            rowType="row"
            image={womensOneDay}
            imageAlt="GIRL POWER"
            header="GIRL POWER"
            subheader="ALL-NEW WOMEN’S CLASSES AVAILABLE"
          >
            <p>
              Our new off-road courses are designed to make you feel comfortable on a BMW bike as you 
              explore new territory after the roads end.
            </p>
            <Link to="/motorrad/schools?desktop=4800&mobile=7500">
              <button className="btn btn-bordered">LEARN MORE</button>
            </Link>
          </FlexCard>
        </>
      );
    }
  };

  getGoProCard =  () => {
    if (goProCard) {
      return (
        <>
        <FlexCard
            rowType="row-reverse"
            image={teamUsaBanner}
            imageAlt="GO PRO"
            header="GO PRO"
            subheader="TRAIN WITH GS TROPHY TEAM USA"
            text="There’s no better way to get a head start on your GS Trophy training than with Team USA as they prepare for Namibia."
        >
          <Link to="/motorrad/experiences">
            <button className="btn btn-bordered">LEARN MORE</button>
          </Link>
        </FlexCard></>
      )
    } else {
      return (
        <FlexCard
            rowType="row-reverse"
            image={bmwmotorradcard}
            imageAlt="BMW Mottorrad Reward"
            header="Rewards At Full Throttle"
            subheader="Earn Rewards On Everyday Purchases"
        >
          <p>
            Designed for riders, the BMW Motorrad Card earns points toward
            rewards and includes exceptional complimentary benefits. With up
            to 4X points per $1 spent on eligible BMW Motorrad purchases,
            earning has never been so easy. It's time to explore your benefits
            - this is your road to valuable rewards.
          </p>
          <Link to="/motorrad/reward?utm_source=MotorradHomePage&utm_medium=banner3&utm_campaign=MotorradCard">
            <button className="btn btn-bordered">Learn More</button>
          </Link>
        </FlexCard>
      )
    }
  };
  render() {
    return (
      <Layout>
        <SEO
          title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
          description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
        />
        <HomeVideoBackground
          headerTitle="Wheels Down, Throttle out, let’s ride"
          url={`${settings.IMAGES_BASE_URL}/v2/videos/Ritta%20Moto%20Edit%20FINAL_noEndCard.mp4`}
          poster={poster}
          motorradvolume="true"
        />
        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h3 className="motorrad__title">THE BMW U.S. RIDER ACADEMY</h3>
            </div>
          </div>
          {this.getFirstCard()}
          {this.getGoProCard()}
          
        </section>

        <section className="testimonial">
          <div className="testimonial__content">
            <h3>
              “I’ve ridden for years and was amazed at the amount of info I
              didn’t have.”
            </h3>
            <span> - Jeff H.</span>
          </div>
        </section>

        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2>BMW RIDING SCHOOLS</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={bmwonroad}
            imageAlt="image of Fast Car"
            header="ON-ROAD"
            subheaderMini="LET THE MOMENTUM GRAB YOU"
            text="Build a momentum of technique, control and comfort in the saddle with every new revolution of the wheel. We’ll cover controlled clutch and throttle movements, low-speed maneuverability, overall balance and more. Every scenario is built to enhance your real-world enjoyment and safety when riding. Rev it up."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner4&utm_campaign=OnRoadGrab"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
          <DrivingSchoolCard
            rowType="row"
            image={bmwoffroad}
            imageAlt="image of Driver School Car"
            header="OFF-ROAD"
            subheaderMini="OPEN THE DOOR TO NEW ADVENTURES"
            text="Only five percent of the world’s roads are paved, which means one thing: You’re going to get dirty. Off-road classes traverse challenging terrain that leaves students with increased confidence and newfound riding ability."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner5&utm_campaign=OffRoadDoor"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
        </section>
      </Layout>
    );
  }
}
export default IndexPage;
