import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class DrivingSchoolCard extends React.Component {
  state = {
    width: '',
    show: false
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCardDisplay = () => {
    const currentState = this.state.show;
    this.setState({ show: !currentState });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 1200;

    return (
      <div className="drivingcard">
        <div className="row">
          <div className="col">
            <div className="drivingcard__image">
              <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.imageAlt} />
            </div>
          </div>
          <div className="col">
            {isMobile && (
              <div
                className={this.state.show ? 'drivingcard__content mobile  hide' : 'drivingcard__content mobile'}
                onClick={this.handleCardDisplay}
                onBlur={this.handleCardDisplay}
                style={{
                  backgroundImage: `url(${this.props.image})`
                }}
                role="presentation"
              >
                <h3>{this.props.header}</h3>
              </div>
            )}

            <div
              className={this.state.show ? 'drivingcard__content' : 'drivingcard__content hide'}
              style={{
                background: isMobile
                  ? `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${this.props.image})`
                  : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.props.image})`
              }}
              onClick={this.handleCardDisplay}
              onBlur={this.handleCardDisplay}
              role="presentation"
            >
              <h3>{this.props.header}</h3>
              <h4>{this.props.subheader}</h4>
              {this.props.subheaderMini && <h6>{this.props.subheaderMini}</h6>}
              <p>{this.props.text}</p>
              <div className="buttons">
                <Link to={this.props.learnUrl}>
                  <button className={`btn ${this.props.learnBtnStyle}`}>Learn More</button>
                </Link>
                {this.props.callBtn ? (
                  <a href="tel:888-345-4269" target="_blank" rel="noopener noreferrer" className="phone-number">
                    <button className={`btn ${this.props.bookBtnStyle}`}>
                      Call 888-345-4269 to reserve your seat now
                    </button>
                  </a>
                ) : (
                  <a
                    href={this.props.bookUrl ? this.props.bookUrl : 'https://pds.eventsbmw.com/'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className={`btn ${this.props.bookBtnStyle}`}>Book Now</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DrivingSchoolCard;
